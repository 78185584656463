import React from 'react';
import { useParams } from 'react-router-dom';
import { sources } from './HomePage';
import { useEffect, useRef } from 'react';
import { Link} from 'react-router-dom';
// import WaveSurfer from 'wavesurfer.js';

const SourcePage = () => {
  let { id } = useParams();
  console.log(id);
  // Fetch source details based on id
  // For now, let's assume we have a function fetchSourceDetails that does this
  // const source = fetchSourceDetails(id);
  const source = sources[id];
  // const waveformRef = useRef(null);
  // let wavesurfer = null;

  // useEffect(() => {
  //   wavesurfer = WaveSurfer.create({
  //     container: waveformRef.current,
  //     waveColor: '#4F4A85',
  //     progressColor: '#383351',
  //   });

  //   wavesurfer.load(source.audioSummaryUri);

  //   return () => wavesurfer.destroy();
  // }, [source]);
  if (!source) {
    return <p>Source not found.</p>;
  }
  console.log(source.audioSummaryUri);
  let parsedTitle = JSON.parse(`"${source.title}"`);
  let parsedAuthors = JSON.parse(`"${source.authors}"`);
  let parsedAbstract = JSON.parse(`"${source.abstract}"`);

  return (
    <div className="source-page">
      <Link to="/">Return Home</Link>
      
      <h1>{parsedTitle}</h1>
      <audio src={source.audioSummaryUri} controls autoPlay />
      <br/>
    
      <h2>{parsedAuthors}</h2>
      <br/>
      <h2>Abstract</h2>
      <br/>
      <p>{parsedAbstract}</p>
      
    </div>
  );
};

export default SourcePage;