import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams, Link} from 'react-router-dom';
import { questions, sources } from './HomePage';
// const questions = [
//     { id: 1, text: "What are the most promising areas of lymphoma research right now?", videoUrl: "/videos/1.mov", sources: [{ title: "Lymphoma Research Foundation", url: "https://lymphoma.org/" }, { title: "American Cancer Society", url: "https://www.cancer.org/" }]},
//     { id: 2, text: "How has our understanding of the different lymphoma subtypes evolved in recent years?", videoUrl: "/videos/2.mov", sources: [{ title: "Lymphoma Research Foundation", url: "https://lymphoma.org/" }, { title: "American Cancer Society", url: "https://www.cancer.org/" }]},
//     { id: 3, text: "Can you discuss any significant challenges in lymphoma treatment and how current research is addressing these?", videoUrl: "/videos/3.mov", sources: [{ title: "Lymphoma Research Foundation", url: "https://lymphoma.org/" }, { title: "American Cancer Society", url: "https://www.cancer.org/" }]},
//     { id: 4, text: "How is the understanding of the tumor microenvironment altering approaches to lymphoma treatment?", videoUrl: "/videos/4.mov", sources: [{ title: "Lymphoma Research Foundation", url: "https://lymphoma.org/" }, { title: "American Cancer Society", url: "https://www.cancer.org/" }]},
//     { id: 5, text: "What advancements in genomics and personalized medicine are most promising for improving lymphoma treatment outcomes?", videoUrl: "/videos/5.mov", sources: [{ title: "Lymphoma Research Foundation", url: "https://lymphoma.org/" }, { title: "American Cancer Society", url: "https://www.cancer.org/" }]},
//     // Add more questions here
//   ];
  
  const VideoPage = () => {
    let { id } = useParams();
    console.log(id);
    const question = questions.find(q => q.id.toString() === id);
  
    // Return early if question is not found
    if (!question) {
      return (
        <div>
          <p>Question not found.</p>
          <Link to="/">Return Home</Link>
        </div>
      );
    }
    for (let i = 0; i < question.sources.length; i++) {
      console.log(sources[question.sources[i]]);
      console.log(i, question.sources[i])
    }
    return (
      <div className="content-container">
        <Link to="/">Return Home</Link>
        <div className="video-sources-container">
          <video src={question.videoUrl} className="video-player" controls autoPlay />
          <div className="sources-list">
            <h1>Explore Sources</h1>

            {question.sources.map((source, index) => (
                
              sources[source] ? <Link key={index} to={`/source/${source}`} className="source-button">{JSON.parse(`"${sources[source].title}"`)}</Link> : null
            ))}
          </div>
        </div>
      </div>
    );
  };
  
  export default VideoPage;
  
